<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Choices from "choices.js";

import RecordsDialog from "./components/records.vue"
import VueBootstrapTypeahead from 'vue-bootstrap-typeahead'

import { useStockApi } from "@/apis/stock";
import { useSystemApi } from "@/apis/system";

/**
 * Advanced table component
 */
export default {
  page: {
    title: '库存清单',
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    RecordsDialog,
    VueBootstrapTypeahead

  },
  data() {
    return {
      tableData: [],
      model_data: [],
      storageList: [],
      title: this.$t('stock.list.sub_title'),
      items: [
        {
          text: this.$t('stock.list.nav_first_item'),
          href: "/",
        },
        {
          text: this.$t('stock.list.nav_second_item'),
          active: true,
        },
      ],
      issue_url: '',
      totalRows: 1,
      currentPage: 1,
      perPage: 10,

      fields: [

        {
          key: "storage_model",
          label: '型号',
          sortable: true,
        },

        {
          key: "model_name",
          label: '名称',
          sortable: true,
        },

        {
          key: "storage_count",
          label: '库存数量',
          sortable: true,
        },

        {
          key: "storage_tag",
          label: '库',
          sortable: true,
        },


        {
          key: "Pos",
          label: this.$t('stock.list.table_fields.action'),
          sortable: false,
        },
      ],

      p_model: '',
      currentStorage: '',
    };
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.totalRows;
    },


  },
  mounted() {

    this.query_storage()
    this.query_stock_list()



  },
  methods: {

    query_stock_list() {
      let data = {
        page: {
          page_no: this.currentPage,
          page_size: this.perPage
        }
      }

      if (this.p_model != '') {
        data.model = this.p_model
        data.page.page_no = 1
      }

      if (this.currentStorage != '') {
        data.storage = this.currentStorage
      }


      useStockApi().list_stock(data).then(res => {
        if (res.err == 0) {
          this.tableData = []
          res.data.map(e => {
            this.tableData.push(e)
          })

          this.totalRows = res.page.total_count
        }
      })
    },

    query_storage() {
      useSystemApi().list_system_conf('STORAGE').then(res => {
        if (res.err == 0) {
          this.storageList = [
            {
              value: '',
              label: '全部',
              selected: true
            }
          ]

          res.data.map(e => {

            this.storageList.push({
              code: e.key_field,
              name: e.value_field,
              remarks: e.remarks,
              id: e.id,
              value: e.key_field,
              label: e.value_field
            })
          })
        }

        new Choices('#storage_list', { choices: this.storageList })
      })
    },

    onStorageChanged(storage) {
      this.currentStorage = storage.detail.value
      this.currentPage = 1
      this.query_stock_list()
    },

    goto_new_produce() {
      this.$router.push({ 'name': 'produce-new' })
    },

    show_records_dialog() {
      this.$bvModal.show('records_dialog')
    },

    search_model(searched_str) {
      useSystemApi().model_search('MODEL', searched_str).then(res => {
        if (res.err == 0) {
          this.model_data = []
          res.data.map(e => {
            this.model_data.push(e.key_field)
          })
        }

      })
    },



    pageChange(p) {
      this.currentPage = p
      this.query_stock_list()
    },


  },

  watch: {
    p_model: function (new_val) {
      this.search_model(new_val)
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />

    <b-modal id="records_dialog" centered title="Add New Event" title-class="text-black font-18" body-class="p-3"
      size="lg" hide-footer hide-header-close no-close-on-backdrop no-close-on-esc>
      <RecordsDialog></RecordsDialog>
    </b-modal>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-sm-6 col-md-3">

                <div class="row">
                  <div class="col-md-2">
                    <label class="d-inline-flex align-items-center"> 型号选择&nbsp;</label>
                  </div>
                  <div class="col-md-6">
                    <vue-bootstrap-typeahead v-model="p_model" :data="model_data" autocomplete="off" :inputClass="{}"
                      @hit="query_stock_list" @clear="query_stock_list" />
                  </div>

                </div>

                <div class="dataTables_length">


                </div>
              </div>

              <div class="col-sm-12 col-md-3">
                <div class="row">
                  <div class="col-md-2">
                    <label class="d-inline-flex align-items-center"> 仓库过滤&nbsp;</label>
                  </div>
                  <div class="col-md-6">
                    <select class="form-control" id="storage_list" @change="onStorageChanged">
                    </select>
                  </div>

                </div>


              </div>

              <div class="col-sm-6 col-md-2">
              </div>
              <!-- Search -->
              <div class="col-sm-6 col-md-4">
                <div class=" d-flex flex-wrap  justify-content-md-end gap-2">
                  <b-button variant="secondary" @click="$router.push({ 'name': 'stock-in' })">入库</b-button>
                  <b-button variant="secondary" @click="$router.push({ 'name': 'stock-out' })">出库</b-button>
                  <b-button variant="secondary" @click="$router.push({ 'name': 'stock-transfer' })">库间流转</b-button>
                  <b-button variant="secondary" @click="$router.push({ 'name': 'stock-records' })">记录查询</b-button>
                  <b-button variant="secondary" @click="$router.push({ 'name': 'stock-records-approve' })">入库审批</b-button>
                </div>
              </div>
              <!-- End search -->
            </div>
            <!-- Row -->



            <div class="table-responsive mb-0">
              <b-table class="datatables" :items="tableData" :fields="fields" responsive="sm" :per-page="perPage">

                <template #cell(Pos)>
                  <b-dropdown variant="white" toggle-class="p-0">
                    <template v-slot:button-content>
                      <i class="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
                    </template>
                    <li>
                      <b-dropdown-item><i class="mdi mdi-pencil font-size-16 text-success me-1"></i> {{
                        $t('stock.list.process.ship') }}
                      </b-dropdown-item>
                    </li>
                    <li>
                      <b-dropdown-item @click="show_records_dialog"><i
                          class="mdi mdi-pencil font-size-16 text-success me-1"></i> {{
                            $t('stock.list.process.check') }} </b-dropdown-item>
                    </li>

                  </b-dropdown>
                </template>
              </b-table>
            </div>
            <div class="row">
              <div class="col">
                <div class="dataTables_paginate paging_simple_numbers float-end">
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage"
                      @change="pageChange"></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </Layout>
</template>
